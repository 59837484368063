.search-toolbar {
  max-width: 800px;
  text-align: center;
  margin: 30px auto 0 auto;
  background-color: white;
  padding-bottom: 14px;
  z-index: 41;

  @media (max-width: 600px) {
    position: sticky;
    top: -134px;
  }

  @media (min-width: 601px) {
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }

  .rbt {
    text-align: left;
    width: 100%;
    z-index: 32;
    //fix position of close [x] button
    align-items: flex-end;
  }

  .search-size {
    font-size: small;
    width: 100%;
    text-align: right;
  }

  .rbt-input {
    font-size: 16px;
  }
}

@media (min-width: 600px) {
  .search-toolbar-logo-container {
    margin: 0 18px 8px 8px;
    min-width: 183px;
  }
}

.search-text-container {
  width: 100%;
}

.search-types-selector-container {
  background-color: white;
  z-index: 1;

  @media (max-width: 600px) {
    margin: 20px auto;
    position: sticky;
    top: 48px;
    z-index: 40;
  }

  @media (min-width: 601px) {
    margin: 20px auto;
    padding-top: 30px;
  }
}

.rtb-menu {
  width: 100%;
}

.search-result-container-with-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}